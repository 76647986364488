import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Node-RED and MQTT",
  "path": "/Advanced_User/Node-RED_and_MQTT/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED and MQTT' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport) Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_und_MQTT/' locationFR='/fr/Advanced_User/Node-RED_and_MQTT/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "node-red-and-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-and-mqtt",
        "aria-label": "node red and mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED and MQTT`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#securing-node-red"
        }}>{`Securing Node-RED`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#setting-application-port-and-urls"
            }}>{`Setting Application Port and URLs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-a-public-directory"
            }}>{`Creating a Public Directory`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-an-administrator-login"
            }}>{`Creating an Administrator login`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#starting-node-red"
        }}>{`Starting Node-RED`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-tutorials"
        }}>{`Node-RED Tutorials`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Node-RED is a programming tool for wiring together hardware devices like your INSTAR IP camera and APIs & online services. It provides a browser-based editor that makes it easy to wire together flows using the wide range of nodes providing inter-connectivity between your cameras REST API and IoT protocols like MQTT (Message Queuing Telemetry Transport).`}</p>
    <p>{`In the following we want to integrate your camera's `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Representational_state_transfer"
      }}>{`REST API`}</a>{` with `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` to allow us to control the camera functions via the `}<a parentName="p" {...{
        "href": "https://mqtt.org"
      }}>{`MQTT protocol`}</a>{`, that is widely used by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Internet_of_things"
      }}>{`IoT devices`}</a>{` and home automation system like `}<a parentName="p" {...{
        "href": "https://www.openhab.org"
      }}>{`OpenHAB`}</a></p>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h2>
    <p>{`To install Node-RED we first need to install Node.js that is available for `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`download here`}</a>{`. Just download the version suitable for your operating system and double-click it to install. Open your Commandline or Terminal and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node -v`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm -v`}</code>{`:`}</p>
    <p>{`to verify that the node runtime was installed successfully:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/824108da4d2056f2e5b0c9daf441fa09/7b1dc/node-red_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAAh0lEQVQI143KTQqCQBxA8f+MH41OjCYdona1aN8uQYwUFCLKKDfdoCN06xdYizZFi9/qPZkeK9KuJjnXuL4lvTYDe9oxOpSE+wLdbNBt/pXX5sh2zexxR7J4jDMRc2NZBoZIBE+E4E2LIH8qLx0yyTJCG7NwCavYvqL6GJVC9G/K94e3uPU8AUdYTdK1hSppAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/824108da4d2056f2e5b0c9daf441fa09/e4706/node-red_01.avif 230w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/d1af7/node-red_01.avif 460w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/7f308/node-red_01.avif 920w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/85cc3/node-red_01.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/824108da4d2056f2e5b0c9daf441fa09/a0b58/node-red_01.webp 230w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/bc10c/node-red_01.webp 460w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/966d8/node-red_01.webp 920w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/409e5/node-red_01.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/824108da4d2056f2e5b0c9daf441fa09/81c8e/node-red_01.png 230w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/08a84/node-red_01.png 460w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/c0255/node-red_01.png 920w", "/en/static/824108da4d2056f2e5b0c9daf441fa09/7b1dc/node-red_01.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/824108da4d2056f2e5b0c9daf441fa09/c0255/node-red_01.png",
              "alt": "Node.js",
              "title": "Node.js",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I this case we installed the latest available version of Node (at the time of writing v10.10.0) that comes with the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com"
      }}>{`Node Package Manager`}</a>{` (npm v6.4.1). We can now use the package manager to `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/getting-started/installation"
      }}>{`install Node-RED`}</a>{` by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install -g --unsafe-perm node-red`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3997e1aaee413c8058ad4a1e41144f01/7b1dc/node-red_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABR0lEQVQY032Qz24TMRCHnbW9u17bu012196mTVQICC4cOYMQyoMQUUJyRaDyJ32ASi1wSMUD8JwfihFVTxw+/WY0Mz/NjJAfVrhPK9rdhvG394n2cstkt6XbbbFf12Sfz5H3yC7eJlVf3v3lkH98w+Pf3xGLM8cD1bKcRF5PT3jZBV60Pa/CwDIc88TXaJ3jjaFxDmcMR77Gmwo1ysilpNCaUgjOf14hnj+ryEWDrY4I81OarqPwnrrrsJMJ474nxkhlLUprpJRorVFKIUYjMikTUgjWtzeIp48aujIyiycsFg+Zz2YMMXI8DHRtSwyB2XxOCD3WObz3OOew1uK8T1o5h1GKza8fiD5MCUOgruvU/E+NMRRFkQYOedM0d/WD0X1j6x25GLHe3yDatEVMZxzI8zz9rCyKFJdlmc7LsgwhxH9Z76/5AxhKoSql+fUhAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3997e1aaee413c8058ad4a1e41144f01/e4706/node-red_02.avif 230w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/d1af7/node-red_02.avif 460w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/7f308/node-red_02.avif 920w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/85cc3/node-red_02.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3997e1aaee413c8058ad4a1e41144f01/a0b58/node-red_02.webp 230w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/bc10c/node-red_02.webp 460w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/966d8/node-red_02.webp 920w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/409e5/node-red_02.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3997e1aaee413c8058ad4a1e41144f01/81c8e/node-red_02.png 230w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/08a84/node-red_02.png 460w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/c0255/node-red_02.png 920w", "/en/static/3997e1aaee413c8058ad4a1e41144f01/7b1dc/node-red_02.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3997e1aaee413c8058ad4a1e41144f01/c0255/node-red_02.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now start Node-RED by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red`}</code>{` in your console - the process should then be accessible via your web browser via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:1880/`}</code>{`. Now stop the process with CTRL + C and go with your console into your Windows user directory - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`C:\\Users\\INSTAR\\.node-red`}</code>{` (replace INSTAR with your Windows user name). `}<strong parentName="p">{`Attention of this directory is only created after the first start of Node-RED`}</strong>{`.`}</p>
    <p>{`In this directory we will now install some Node modules, which we will need later. First, you can type the following command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install path`}</code>{`. This will install the `}<strong parentName="p">{`Path`}</strong>{` module in the Node-RED directory, which we will later use to set up a `}<a parentName="p" {...{
        "href": "#creating-a-public-directory"
      }}>{`public-access directory`}</a>{` for the Node-RED webserver.`}</p>
    <p>{`We later want to use the Node-RED dashboard to interact with our camera which is an additional module we need to install. First use your Commandline or Terminal to navigate to the Node-RED installation directory (under Windows this will be a `}<strong parentName="p">{`.node-red`}</strong>{` folder inside your user directory) and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install node-red-dashboard`}</code>{` to install the user interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de56ff1b274f86a8409534100f296eb8/7b1dc/node-red_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAA5UlEQVQY042OsU7CUBhG/3pbW9t74VK03GtACcTo4uTi4qaYxsQSVKYuxsXiC/gEvPYxJZ2MGoeTM30nnxysSmR5h1+XXNQV8/qReV1xVlfYdYmsbpHnxZ8EL/fIww1X209k4gzHecos63OpeyzzgtXgiKe8YGEGXCeaExFEhKDzd1Tnj9c3ZHZuGU80/cMRuXf44ZBCG5y1eGspkoQ4CJCWvZ9RUbQLvm82SOHHuJFnOj3FO0/hHDbPyYwh1YYwjgn3I4I2+NtDpXZumgZphz1j0EaTZhlJEhNFEaFSO6vOEsi/gl9El364Nc9JUwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de56ff1b274f86a8409534100f296eb8/e4706/node-red_03.avif 230w", "/en/static/de56ff1b274f86a8409534100f296eb8/d1af7/node-red_03.avif 460w", "/en/static/de56ff1b274f86a8409534100f296eb8/7f308/node-red_03.avif 920w", "/en/static/de56ff1b274f86a8409534100f296eb8/85cc3/node-red_03.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de56ff1b274f86a8409534100f296eb8/a0b58/node-red_03.webp 230w", "/en/static/de56ff1b274f86a8409534100f296eb8/bc10c/node-red_03.webp 460w", "/en/static/de56ff1b274f86a8409534100f296eb8/966d8/node-red_03.webp 920w", "/en/static/de56ff1b274f86a8409534100f296eb8/409e5/node-red_03.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de56ff1b274f86a8409534100f296eb8/81c8e/node-red_03.png 230w", "/en/static/de56ff1b274f86a8409534100f296eb8/08a84/node-red_03.png 460w", "/en/static/de56ff1b274f86a8409534100f296eb8/c0255/node-red_03.png 920w", "/en/static/de56ff1b274f86a8409534100f296eb8/7b1dc/node-red_03.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de56ff1b274f86a8409534100f296eb8/c0255/node-red_03.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "securing-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#securing-node-red",
        "aria-label": "securing node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Securing Node-RED`}</h2>
    <p>{`We want our Node-RED installation to only be reachable from our secure local network. If you want to access the application over the internet you should read a little deeper on how to `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/security"
      }}>{`secure your connection`}</a>{`! For our purposes we want there to be an `}<strong parentName="p">{`administrator login`}</strong>{` to prevent other users to change our setup, while `}<strong parentName="p">{`anonymous user`}</strong>{` will only have the right to `}<strong parentName="p">{`read`}</strong>{`. We also want to create a `}<strong parentName="p">{`public directory`}</strong>{` that we can use to save files in and access them from the user interface. And lastly we will set the port and URL for our Node-RED application. All of this can be done in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{` in the Node-RED installation directory (under Windows this will be a `}<strong parentName="p">{`.node-red`}</strong>{` folder inside your user directory):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6d2fa95919e385befb4da9a191801ba0/7b1dc/node-red_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwElEQVQoz5VSyW4TQRCdL+NTuCBFMT/AT3HgwAGBiHNCCpdgpCgHvMTLjO2e6bV6nV4qmrFNEJwoPbWqX/erelJV9er9h3ffP199+/jm7tPV/dfJbHr94+Z6Np3MppOftwNmt5Pf18v59v7m9d2X6rGxvY/YQ7a+hIR9LD4UH9AFLAULvkTKA/qEaWRzqYQxza7WxhqjQJE8kCWXUhCNdZRxbYxUkHKKKaWcz6/Dh1KBpoKxXNA7qflKSsg5nzp574XgACCEiCniP1FtuBJCIiKlB3qcW+MQcWw8iDnnCkBKGVManOaTp4t4TfVms/POMc7I8bhcLAkhANpZ55xjlEkpu7Z13iOi1jqNVc7iVnvasVJKR7u6Xi8Wy7pummbPOQet27aTUrVt50NARPhL/DhfrFYrAN3sd83uV71ZC9opzlKMwTuQ3Gowiuc8aHrvTsnFdl1vt1trbXNoHubzh+XhAJF6pA5r7pZ79kTUE5FHSCNj+/iHmEvREhJCT7puRzoijI4ofYGA3MQj1620rXIdeDUwfRpnUUZUXAFjzBjDOcf/jGrXMiXBusD4MI8yrNW5cCknlHOCLysXYmbGPwOp/KH4m31L4AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d2fa95919e385befb4da9a191801ba0/e4706/node-red_04.avif 230w", "/en/static/6d2fa95919e385befb4da9a191801ba0/d1af7/node-red_04.avif 460w", "/en/static/6d2fa95919e385befb4da9a191801ba0/7f308/node-red_04.avif 920w", "/en/static/6d2fa95919e385befb4da9a191801ba0/85cc3/node-red_04.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6d2fa95919e385befb4da9a191801ba0/a0b58/node-red_04.webp 230w", "/en/static/6d2fa95919e385befb4da9a191801ba0/bc10c/node-red_04.webp 460w", "/en/static/6d2fa95919e385befb4da9a191801ba0/966d8/node-red_04.webp 920w", "/en/static/6d2fa95919e385befb4da9a191801ba0/409e5/node-red_04.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d2fa95919e385befb4da9a191801ba0/81c8e/node-red_04.png 230w", "/en/static/6d2fa95919e385befb4da9a191801ba0/08a84/node-red_04.png 460w", "/en/static/6d2fa95919e385befb4da9a191801ba0/c0255/node-red_04.png 920w", "/en/static/6d2fa95919e385befb4da9a191801ba0/7b1dc/node-red_04.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6d2fa95919e385befb4da9a191801ba0/c0255/node-red_04.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "setting-application-port-and-urls",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setting-application-port-and-urls",
        "aria-label": "setting application port and urls permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting Application Port and URLs`}</h3>
    <p>{`The following lines can be found in the `}<strong parentName="p">{`module.exports block`}</strong>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{` and can be used to change the default values used for the parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`uiPort`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` process`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`PORT`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1880`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
httpAdminRoot`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
ui`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dashboard"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span></code></pre></div>
    <p>{`This will set the port of our Node-RED application to `}<strong parentName="p">{`1880`}</strong>{`, the URL for the admin panel to `}<strong parentName="p">{`/admin`}</strong>{` and the URL for the dashboard to `}<strong parentName="p">{`/dashboard`}</strong>{`.`}</p>
    <h3 {...{
      "id": "creating-a-public-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-a-public-directory",
        "aria-label": "creating a public directory permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a Public Directory`}</h3>
    <p>{`In later steps we want to use Node-RED to save snapshots and videos from our camera and display them on the installed dashboard. For this we need to define a directory that we can access publicly. We can create a folder inside installation directory called `}<strong parentName="p">{`static`}</strong>{` (see screenshot above) and set the following line inside the `}<strong parentName="p">{`module.exports`}</strong>{` block:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`httpStatic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`join`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`__dirname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'static'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span></code></pre></div>
    <p>{`You can see that this uses the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Path`}</code>{` module we installed before. This must be imported in the header of the file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// var fs = require("fs");`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"path"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3 {...{
      "id": "creating-an-administrator-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-an-administrator-login",
        "aria-label": "creating an administrator login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating an Administrator login`}</h3>
    <p>{`Just use the following lines inside `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{` to allow anonymous users to view your Node-RED admin panel and dashboard but require a login for editing nodes:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`adminAuth`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"credentials"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        users`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            password`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$2a$08$k.syiLYKsLGJdy01hPolj.m7dfgZXWz0do4oKkesLrJExoYgFBZYu"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            permissions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            permissions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"read"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span></code></pre></div>
    <p>{`You can see that the login is hashed using the bcrypt algorithm. Node-RED offers a `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/node-red-admin"
      }}>{`tool to create a hash`}</a>{` of your personal login. You can install it globally on your machine by typing the following `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install -g node-red-admin`}</code>{`. Then start the program with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red-admin hash-pw`}</code>{` and type in your desired password. Copy the created hash into the password field for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`adminAuth`}</code>{` (see above).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4841a0c2e5c178e80e33473a7b3927f6/6bff2/node-red_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAAmklEQVQY053Muw6CQBCF4VniGmVxL7jYCCRaYJB4KYzG+C40Yq+V4ssfA2uoNCYWX2aa8xO7lWDXEl59AdUV6FGB2t9hT4d+uZ/Bmq0vAoTWQkoFzvsgor/1mntMUqxMiHU4xsZGnW00QaENMhEg8wUGnvc9xpgLcg46zebYTWPs4wSHJEWudGupDRYjiVwb5FJh+B59DlIXfAFc12h3ctNuBwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4841a0c2e5c178e80e33473a7b3927f6/e4706/node-red_05.avif 230w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/d1af7/node-red_05.avif 460w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/7f308/node-red_05.avif 920w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/b4975/node-red_05.avif 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4841a0c2e5c178e80e33473a7b3927f6/a0b58/node-red_05.webp 230w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/bc10c/node-red_05.webp 460w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/966d8/node-red_05.webp 920w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/c15ec/node-red_05.webp 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4841a0c2e5c178e80e33473a7b3927f6/81c8e/node-red_05.png 230w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/08a84/node-red_05.png 460w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/c0255/node-red_05.png 920w", "/en/static/4841a0c2e5c178e80e33473a7b3927f6/6bff2/node-red_05.png 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4841a0c2e5c178e80e33473a7b3927f6/c0255/node-red_05.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "starting-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starting-node-red",
        "aria-label": "starting node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting Node-RED`}</h2>
    <p>{`Now, with everything configured, we are finally able to get started! Type in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red`}</code>{` to execute the program and open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:1880/admin/`}</code>{` in your web browser:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/6bff2/node-red_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABuElEQVQoz5WSS2/TQBSFx4/EaZyY2LHjt+NXxk3Imh2iZUkjhBDiIV4F1CAKQmLNb+A3f8iTqrCoVFgcje7V3HPPOTNifP4Uc/8c88tLrMtX1N/fkX99jbh49n94u6P89RMRBQsS1yOYOoTjCStjyFJoCCH+GdrVuXv/BhHEMd58ztz3MS0LoesIw0A3TYyBiWYYCE079HXtBujoA1MRnn3+hGi7jrZp8X2fsqrYrNdUZUldVdR1w3Q6vV2ldnD06OIcIddrmqZhWRTkRU6e5xRFoZAkKY7jMJvN8DyP+dxXi4Mg4I7j4Lp97+DOHg558eMbopGSuq4V6WKxUJf74X7wbxLPdZVa27axLIvBYMBwOGQ0shgdHSmFTy73iNXxMXIlSZOEsixJ4oQoiojjWG0Pw/B6SV9PJpPbLXeyI8sylWFvNU1TpSbte2XJclmqSPr6pkx1Qz+88v4jousJu448y6irGimlsh9FIW27YrvdcnezoZOSKIxwZ7Nry9qVMk0/EJ7tPyBaKamaFpmmPK4aTuKU+0HIg0XESZRwGqecxgkPk4xd3XIvjBjbtsrS6L9UT2j8IfwNt/oCdhhT7moAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/e4706/node-red_06.avif 230w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/d1af7/node-red_06.avif 460w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/7f308/node-red_06.avif 920w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/b4975/node-red_06.avif 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/a0b58/node-red_06.webp 230w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/bc10c/node-red_06.webp 460w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/966d8/node-red_06.webp 920w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/c15ec/node-red_06.webp 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/81c8e/node-red_06.png 230w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/08a84/node-red_06.png 460w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/c0255/node-red_06.png 920w", "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/6bff2/node-red_06.png 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9fb08ddf838d4fb4aea01db8e705d5a5/c0255/node-red_06.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click the user icon in the top right to login with the admin account you created earlier:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/deacb6ca7cfa353b296938d56f80a5c6/7b1dc/node-red_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABTUlEQVQY02MIjwiPT0rKK8hPy8iIiovz8vN38/Zx9/GFI08/f08/P3cfX2cvL1sPdxtPD1tzKycjE2NrawYTQ6O4yMiOxsaG6prqmpqKquqq6ho4qq6pqW9obGxqauvumT5h8qZZC5dOn13mG5xtaKxrbs5QkJ2TlZ0dHRMdHBwcEhISGhoaGBgYFBQUGhoaFBQIEQkNDpbm41NnZIsVlAtgE5JnZTPX1k5PTWP48f3b//////z58+/fv/+4waWNm3fPmbt55sxts2dvmzr98pat////Z9h76vLp6/dv3b3/9cuXX79+/fz58xcYQBg/weDXr1+YRr97947h3JI151euf3jj5o9fv/79RYA/f/4g8f7+QgI/f/789/fv06dPGc5c23X2+r5Xb579/vX7L27NyNw/f/5Abb7x6M7Nh3c/fPr45zexmiHgx48fANWOWgIWMq1ZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deacb6ca7cfa353b296938d56f80a5c6/e4706/node-red_07.avif 230w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/d1af7/node-red_07.avif 460w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/7f308/node-red_07.avif 920w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/85cc3/node-red_07.avif 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/deacb6ca7cfa353b296938d56f80a5c6/a0b58/node-red_07.webp 230w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/bc10c/node-red_07.webp 460w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/966d8/node-red_07.webp 920w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/409e5/node-red_07.webp 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/deacb6ca7cfa353b296938d56f80a5c6/81c8e/node-red_07.png 230w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/08a84/node-red_07.png 460w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/c0255/node-red_07.png 920w", "/en/static/deacb6ca7cfa353b296938d56f80a5c6/7b1dc/node-red_07.png 956w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/deacb6ca7cfa353b296938d56f80a5c6/c0255/node-red_07.png",
              "alt": "Node-RED",
              "title": "Node-RED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br /><br />
    <h2 {...{
      "id": "node-red-tutorials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-tutorials",
        "aria-label": "node red tutorials permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Tutorials`}</h2>
    <br />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Introduction/"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Intermediate/"
        }}>{`Intermediate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Advanced/"
        }}>{`Advanced`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/"
        }}>{`Node-RED Projects`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Smartphone_Integration/"
        }}>{`Smartphone Integration`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      